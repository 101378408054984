import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

const BlogHome = () => {
  const router = useRouter();
  useEffect(() => {
    router.replace(process.env.NEXT_PUBLIC_APP_ROOT);
  }, []);
  return <div />;
};

export default BlogHome;
